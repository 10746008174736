import HelloWorld from '@/component/hello-world'

export default {
    components: {
        HelloWorld
    },
    data() {
        return {
            fullscreenLoading: false,
            dialogVisible: false
        };
    },
    methods: {
        open() {
            this.$alert('这是一段内容', '标题名称', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$message({
                        type: 'info',
                        message: `action: ${action}`
                    });
                }
            });
        },

        openFullScreen1() {
            this.fullscreenLoading = true;
            setTimeout(() => {
                this.fullscreenLoading = false;
            }, 2000);
        },

        openFullScreen2() {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                loading.close();
            }, 2000);
        },

        handleClose(done) {
            done();
            // this.$confirm('确认关闭？')
            //     .then(_ => {
            //         done();
            //     })
            //     .catch(_ => {
            //     });
        },

        __btn_open_url_click_event_handler() {
            const __this = this;
            const $router = __this.$router;

            const resolved = $router.resolve({
                name: 'admin-home',
                query: {
                    aaa: 111,
                    bbb: 222,
                },
            });
            const href = resolved.href;
            console.log(href);
            window.open(href);
        },
    },
    created() {
        // const __this = this;
    },
    mounted() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
